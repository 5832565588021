import React, { useState, useEffect } from 'react';
import Row from './Row';
import getMatchingReservations from '../services/getMatchingReservations';
import styles from '../styles.module.scss';

const Section = ({
    sectionNumber,
    level,
    children,
    reservations,
    rowDetails,
    gridSystem,
    circle,
    identity
}) => {
    const [designLine, setDesignLine] = useState(false);
    const [sectionStyles, setSectionStyles] = useState({});
    const [inlineStyle, setInlineStyle] = useState({});

    useEffect(() => {
        setSectionStyles(
            rowDetails.sectionStyles
                ? JSON.parse(rowDetails.sectionStyles.replace(/&quot;/g, '"'))
                : {}
        );

        setInlineStyle({
            left: `${rowDetails.left}px`,
            top: `${rowDetails.top}px`,
            transform: `rotate(${
                rowDetails.rotateOnSection ? rowDetails.rotate : '0'
            }deg)`,
            position: gridSystem ? 'relative' : 'absolute'
        });

        if (children[0].name === 'svg') {
            setDesignLine(children[0]);
        }
    }, []);

    const decodeSVGAttributes = (attrs) => {
        const style = attrs.style ? JSON.parse(attrs.style) : {};
        return {
            ...attrs,
            style
        };
    };

    if (designLine) {
        return (
            <div className='designLine' style={sectionStyles}>
                <svg {...designLine.attributes}>
                    {designLine.children.map((item, i) => {
                        if (item.name === 'rect') {
                            return React.createElement(
                                item.name,
                                {
                                    ...decodeSVGAttributes(item.attributes),
                                    key: i + '' + identity.section
                                },
                                item.value
                            );
                        }
                    })}
                    {designLine.children.map((item, i) => {
                        if (item.name === 'text') {
                            return React.createElement(
                                item.name,
                                {
                                    ...decodeSVGAttributes(item.attributes),
                                    key: i + '' + identity.section
                                },
                                item.value
                            );
                        }
                    })}
                </svg>
            </div>
        );
    } else {
        return (
            <div
                className={`section ${styles.section} ${sectionNumber}`}
                style={{ ...inlineStyle, ...sectionStyles }}
            >
                {children &&
                    children.map((row, i) => (
                        <Row
                            key={`${identity.section}${row.attributes.name}${i}`}
                            identity={{
                                ...identity,
                                row: Number(row.attributes.cr_row)
                            }}
                            rowName={row.attributes.name}
                            section={{ ...level, section: sectionNumber }}
                            reservations={getMatchingReservations(
                                reservations,
                                'row',
                                Number(row.attributes.cr_row)
                            )}
                            rowDetails={rowDetails}
                            breakAfter={rowDetails.breaksAfter.includes(`${i}`)}
                            rowStyles={row.attributes.styles}
                            circle={circle}
                            {...row}
                        />
                    ))}
            </div>
        );
    }
};

export default Section;
