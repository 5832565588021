import deepContains from './deepContains';

const getMatchingReservations = (
    reservations,
    field,
    value,
    record = false
) => {
    return record
        ? reservations.filter((reservation) =>
              deepContains(reservation.seats, record)
          )
        : reservations.filter(
              (reservation) =>
                  reservation.seats.filter((seat) => seat[field] === value)
                      .length > 0
          );
};

export default getMatchingReservations;
