import React from 'react';
import Section from './Section';
import getMatchingReservations from '../services/getMatchingReservations';
import styles from '../styles.module.scss';

const Level = ({
    levelName,
    children,
    reservations,
    custom,
    gridSystem,
    attributes,
    identity
}) => {
    // const [available, setAvailable] = useState(0);
    const addStyles = parseInt(custom.height) > 0;

    // console.log('Grid system on:', gridSystem);
    const gridStyle = attributes.styles
        ? JSON.parse(attributes.styles.replace(/&quot;/g, '"'))
        : {};

    const inlineStyles =
        (addStyles &&
            !gridSystem && {
                top: `${custom.yPos}px`,
                left: `${custom.xPos}px`,
                boxShadow: `0px ${-2 + -parseInt(custom.height)}px ${
                    5 + parseInt(custom.height)
                }px #33333324`,
                width: `${custom.boxWidth}px`,
                height: `${custom.boxHeight}px`
            }) ||
        {};

    return (
        <div
            className={`level ${addStyles ? styles.customLevel : ''}`}
            style={{ ...inlineStyles, ...gridStyle }}
        >
            {addStyles && (
                <div className={styles.customLevelLabel}>{levelName}</div>
            )}
            {children &&
                children.map((section, i) => (
                    <Section
                        key={`${section.attributes.number}${i}`}
                        identity={{
                            ...identity,
                            section: Number(section.attributes.cr_section)
                        }}
                        sectionNumber={section.attributes.number}
                        level={{ level: levelName }}
                        reservations={getMatchingReservations(
                            reservations,
                            'section',
                            Number(section.attributes.cr_section)
                        )}
                        rowDetails={{
                            left: section.attributes.xPos,
                            top: section.attributes.yPos,
                            rotateOnSection: section.attributes.rotateOnSection,
                            rotate: section.attributes.rotate,
                            margin: section.attributes.margin,
                            breaksAfter:
                                (section.attributes.breakOn &&
                                    section.attributes.breakOn.split(',')) ||
                                [],
                            alignment: section.attributes.alignment
                                ? section.attributes.alignment
                                : 'flex-start',
                            sectionStyles: section.attributes.styles
                        }}
                        circle={section.attributes.circle}
                        gridSystem={gridSystem}
                        {...section}
                    />
                ))}
        </div>
    );
};

export default Level;
