import React from 'react';
import Seat from './Seat';
import getMatchingReservations from '../services/getMatchingReservations';
import styles from '../styles.module.scss';

const Row = ({
    rowName,
    section,
    children,
    reservations,
    rowDetails,
    breakAfter,
    rowStyles,
    identity,
    circle
}) => {
    const inlineStyles = {
        transform: `rotate(${
            !rowDetails.rotateOnSection ? rowDetails.rotate : '0'
        }deg)`,
        marginBottom: `${rowDetails.margin}px`,
        justifyContent: rowDetails.alignment
    };
    inlineStyles.marginBottom = breakAfter && '30px';

    const rowStyle = rowStyles
        ? JSON.parse(rowStyles.replace(/&quot;/g, '"'))
        : {};

    const row = { ...section, row: rowName };

    return (
        <div
            className={`${styles.row} row ${
                circle ? `circle ${circle} ${styles[circle]}` : ''
            }`}
            style={{ ...inlineStyles, ...rowStyle }}
        >
            {children &&
                children.map((seat, i) => (
                    <Seat
                        key={`${identity.section}-${identity.row}-${seat.attributes.number}-${i}`}
                        identity={{
                            ...identity,
                            seat: Number(seat.attributes.cr_seat)
                        }}
                        seatNumber={seat.attributes.number}
                        row={row}
                        disabled={seat.attributes.disabled}
                        handicap={seat.attributes.handicap}
                        invisible={seat.attributes.invisible}
                        reservations={getMatchingReservations(
                            reservations,
                            'seat',
                            Number(seat.attributes.cr_seat),
                            reservations.length
                                ? {
                                      ...identity,
                                      seat: Number(seat.attributes.cr_seat)
                                  }
                                : false
                        )}
                        {...seat}
                    />
                ))}
        </div>
    );
};

export default Row;
