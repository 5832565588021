import { useState, useEffect } from 'react';
import XMLParser from 'react-xml-parser';

const useMapTemplate = (templateXML) => {
    const [templateObject, setTemplateObject] = useState({});
    useEffect(() => {
        if (templateXML.length) {
            setTemplateObject(new XMLParser().parseFromString(templateXML));
        }
    }, [templateXML]);
    return templateObject;
};

export default useMapTemplate;
