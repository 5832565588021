const isSeatEqual = (item, elem) => {
    return (
        item.level === elem.level &&
        item.section === elem.section &&
        item.row === elem.row &&
        item.seat === elem.seat
    );
};

export default isSeatEqual;
