import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';
import Level from './components/Level';
import useMapTemplate from './hooks/useMapTemplate';
import getMatchingReservations from './services/getMatchingReservations';
import { useMediaQuery } from 'react-responsive';

import { MapInteractionCSS } from 'react-map-interaction';

export const SeatSelectorContext = React.createContext([[], () => {}]);

export const SeatMap = ({
    mapTemplate,
    reservations,
    error,
    selections,
    onSelect,
    admin,
    showNumbers = false,
    openSeats
}) => {
    const [selectedSeats, setSelectedSeats] = useState(selections);
    const [isAdmin, setIsAdmin] = useState(false);
    const [numbering, setNumbering] = useState(false);
    const [seats, setSeats] = useState({});

    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
    });

    useEffect(() => {
        setSelectedSeats(selections);
    }, [selections]);

    useEffect(() => {
        onSelect(selectedSeats);
    }, [selectedSeats]);

    useEffect(() => {
        if (admin === true) {
            setIsAdmin(true);
        }
        // console.log('numbering setting ', showNumbers);
        setNumbering(showNumbers);
    }, [showNumbers]);

    useEffect(() => {
        const keys = Object.keys(seats);
        const sortedSeats = { available: {}, reserved: {}, blockout: {} };
        for (const key of keys) {
            if (seats[key] === 'available') {
                sortedSeats.available = {
                    ...sortedSeats.available,
                    [key]: true
                };
            } else if (seats[key] === 'reserved') {
                sortedSeats.reserved = {
                    ...sortedSeats.reserved,
                    [key]: true
                };
            } else if (seats[key] === 'blockout') {
                sortedSeats.blockout = {
                    ...sortedSeats.blockout,
                    [key]: true
                };
            }
        }
        openSeats({
            available: Object.keys(sortedSeats.available).length,
            reserved: Object.keys(sortedSeats.reserved).length,
            blockout: Object.keys(sortedSeats.blockout).length
        });
    }, [seats]);

    const calcSeatNumbers = (seat, status) => {
        setSeats((previousValue) => ({
            ...previousValue,
            [`f${seat.level}x${seat.section}r${seat.row}s${seat.seat}`]: status
        }));
    };

    const templateObject = useMapTemplate(mapTemplate);
    const mapHierarchy = (
        <div
            className={
                templateObject.attributes &&
                templateObject.attributes.gridSystem
                    ? styles.gridMap
                    : ''
            }
        >
            {templateObject.children &&
                templateObject.children.map((level) => (
                    <Level
                        key={level.attributes.name}
                        identity={{
                            level: Number(level.attributes.cr_level)
                        }}
                        levelName={level.attributes.name}
                        reservations={getMatchingReservations(
                            reservations,
                            'level',
                            Number(level.attributes.cr_level)
                        )}
                        custom={{
                            height: level.attributes.height,
                            yPos: level.attributes.yPos,
                            xPos: level.attributes.xPos,
                            boxWidth: level.attributes.boxWidth,
                            boxHeight: level.attributes.boxHeight
                        }}
                        gridSystem={templateObject.attributes.gridSystem}
                        {...level}
                    />
                ))}
            {error ? <div>{error}</div> : null}
        </div>
    );

    return (
        <SeatSelectorContext.Provider
            value={[
                selectedSeats,
                setSelectedSeats,
                isAdmin,
                numbering,
                calcSeatNumbers
            ]}
        >
            {isTabletOrMobileDevice ? (
                mapHierarchy
            ) : (
                <MapInteractionCSS
                    showControls
                    controlsClass={styles.buttonWrap}
                    minusBtnContents={
                        <svg
                            heigth='512'
                            width='512'
                            viewBox='0 0 512 512'
                            role='img'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                fill='currentColor'
                                d='M304 192v32c0 6.6-5.4 12-12 12H124c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm201 284.7L476.7 505c-9.4 9.4-24.6 9.4-33.9 0L343 405.3c-4.5-4.5-7-10.6-7-17V372c-35.3 27.6-79.7 44-128 44C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 48.3-16.4 92.7-44 128h16.3c6.4 0 12.5 2.5 17 7l99.7 99.7c9.3 9.4 9.3 24.6 0 34zM344 208c0-75.2-60.8-136-136-136S72 132.8 72 208s60.8 136 136 136 136-60.8 136-136z'
                            />
                        </svg>
                    }
                    plusBtnContents={
                        <svg
                            heigth='512'
                            width='512'
                            viewBox='0 0 512 512'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                fill='currentColor'
                                d='M304 192v32c0 6.6-5.4 12-12 12h-56v56c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-56h-56c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h56v-56c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v56h56c6.6 0 12 5.4 12 12zm201 284.7L476.7 505c-9.4 9.4-24.6 9.4-33.9 0L343 405.3c-4.5-4.5-7-10.6-7-17V372c-35.3 27.6-79.7 44-128 44C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 48.3-16.4 92.7-44 128h16.3c6.4 0 12.5 2.5 17 7l99.7 99.7c9.3 9.4 9.3 24.6 0 34zM344 208c0-75.2-60.8-136-136-136S72 132.8 72 208s60.8 136 136 136 136-60.8 136-136z'
                            />
                        </svg>
                    }
                    btnClass={styles.button}
                    translationBounds={{ yMax: 0, xMax: 0 }}
                    minScale={0.4}
                >
                    {mapHierarchy}
                </MapInteractionCSS>
            )}
        </SeatSelectorContext.Provider>
    );
};

SeatMap.defaultProps = {
    error: null,
    reservations: [],
    selections: []
};

SeatMap.propTypes = {
    error: PropTypes.object,
    mapTemplate: PropTypes.any.isRequired,
    reservations: PropTypes.array.isRequired,
    selections: PropTypes.array,
    onSelect: PropTypes.func.isRequired
};
